/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { MdWorkOutline } from "react-icons/md";
import { AiOutlineBook } from "react-icons/ai";
import { AiOutlineMessage } from "react-icons/ai";
import { BsGear } from "react-icons/bs";

function Nav(props) {
  return (
    <nav>
      <a href="#" className={props.setNavHeader ? "active" : null}>
        <AiOutlineHome />
        <span>Home</span>
      </a>
      <a href="#about" className={props.setNavAbout ? "active" : null}>
        <AiOutlineUser />
        <span>About</span>
      </a>
      <a
        href="#experience"
        className={props.setNavExperience ? "active" : null}
      >
        <MdWorkOutline />
        <span>Experience</span>
      </a>
      <a href="#service" className={props.setNavService ? "active" : null}>
        <BsGear />
        <span>Services</span>
      </a>
      <a href="#portfolio" className={props.setNavPortfolio ? "active" : null}>
        <AiOutlineBook />
        <span>Projects</span>
      </a>
      <a href="#contact" className={props.setNavContact ? "active" : null}>
        <AiOutlineMessage />
        <span>Contact</span>
      </a>
    </nav>
  );
}

export default Nav;
