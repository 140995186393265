import React from "react";
import { useInView } from "react-intersection-observer";
import Header from "./header/Header";
import Nav from "./nav/Nav";
import About from "./about/About";
import Service from "./service/Service";
import Experience from "./experience/Experience";
import Portfolio from "./portfolio/Portfolio";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";

function App() {
  // Making the Nav bar dynamic when scrolling
  const [headerInView, setHeaderInView] = useInView({ threshold: 1 });
  const [aboutInView, setAboutInView] = useInView({
    threshold: 0.5,
  });
  const [experienceInView, setExperienceInView] = useInView({
    threshold: 0.5,
  });
  const [serviceInView, setServiceInView] = useInView({
    threshold: 0.5,
  });
  const [portfolioInView, setPortfolioInView] = useInView({
    threshold: 0.5,
  });
  const [contactInView, setContactInView] = useInView({
    threshold: 0.5,
  });

  return (
    <>
      <Header setHeader={headerInView} />
      <Nav
        setNavHeader={setHeaderInView}
        setNavAbout={setAboutInView}
        setNavExperience={setExperienceInView}
        setNavService={setServiceInView}
        setNavPortfolio={setPortfolioInView}
        setNavContact={setContactInView}
      />
      <About setAbout={aboutInView} setAboutAnimate={setAboutInView} />
      <Experience
        setExperience={experienceInView}
        setExperienceAnimate={setExperienceInView}
      />
      <Service
        setService={serviceInView}
        setServiceAnimate={setServiceInView}
      />
      <Portfolio
        setPortfolio={portfolioInView}
        setPortfolioAnimate={setPortfolioInView}
      />
      <Contact
        setContact={contactInView}
        setContactAnimate={setContactInView}
      />
      <Footer />
    </>
  );
}

export default App;
