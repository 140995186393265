import React from "react";
import "./contact.css";
import DeskSetup from "../../assets/desk-setup.jpg";

import { useForm } from "@formspree/react";

function Contact(props) {
  const [state, handleSubmit] = useForm("xrgropoy");

  // Clears form if it is submitted sucessfully
  if (state.succeeded) {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
  }

  return (
    <section id="contact" ref={props.setContact}>
      <h5
        className={
          props.setContactAnimate ? "heading-animate" : "section-animateout"
        }
      >
        Got A Project? Get In Touch
      </h5>
      <h2
        className={
          props.setContactAnimate ? "heading-animate" : "section-animateout"
        }
      >
        Contact Me
      </h2>

      <div
        className={`container contact__container ${
          props.setContactAnimate ? "panel-animate" : "section-animateout"
        }`}
      >
        <div className="contact__options">
          <div className="contact__option-img">
            <img src={DeskSetup} alt="setup" />
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Full Name" required />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required
          />
          <textarea
            id="message"
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={state.submitting}
          >
            Send Message
          </button>

          <p
            className={
              state.succeeded === true ? "form__status-active" : "form__status"
            }
          >
            Thank you for your message! I will get back to you shortly 😄
          </p>
        </form>
      </div>
    </section>
  );
}

export default Contact;
