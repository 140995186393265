import React from "react";
import "./about.css";
import ProfilePhoto from "../../assets/about-photo.jpg";
import { BsCodeSlash } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { AiOutlineFolder } from "react-icons/ai";

function About(props) {
  return (
    <section id="about" ref={props.setAbout}>
      <div className="container">
        <h5
          className={
            props.setAboutAnimate ? "heading-animate" : "section-animateout"
          }
        >
          Get To Know
        </h5>
        <h2
          className={
            props.setAboutAnimate ? "heading-animate" : "section-animateout"
          }
        >
          About Me
        </h2>
      </div>
      <div
        className={`container about__container ${
          props.setAboutAnimate ? "panel-animate" : "section-animateout"
        }`}
      >
        <div className="about__me">
          <div className="about__me-image">
            <img src={ProfilePhoto} alt="About Image" aria-hidden />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <BsCodeSlash className="about__icon" />
              <h4>Experience</h4>
              <small>2+ Years</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h4>Clients</h4>
              <small>10+ Worked</small>
            </article>
            <article className="about__card">
              <AiOutlineFolder className="about__icon" />
              <h4>Projects</h4>
              <small>20+ Completed</small>
            </article>
          </div>
          <p>
            Greetings! I'm Daniel, an enthusiastic digital creator who takes
            great pleasure in constructing innovative solutions. My purpose
            revolves around empowering businesses by transforming their visions
            into reality through the magic of coding. Currently, I am engaged as
            a Freelancer and Front-End Developer at Link Group, constantly
            honing my skills. My ultimate ambition is to evolve as both a
            skilled designer and a proficient coder, enabling me to craft
            remarkable digital experiences on the web.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
