import ABI from "../../assets/portfolio/tn-abi.jpg";
import BlackJade from "../../assets/portfolio/tn-blackjade.jpg";
import LegendsDental from "../../assets/portfolio/tn-legendsdental.jpg";
import DentalCareCentre from "../../assets/portfolio/tn-dcc.jpg";
import DPI from "../../assets/portfolio/tn-dpi.jpg";
import StarWardrobes from "../../assets/portfolio/tn-starwardrobes.jpg";
import Cura from "../../assets/portfolio/tn-cura.jpg";
import EnneAndBees from "../../assets/portfolio/tn-enneandbees.jpg";
import Peonee from "../../assets/portfolio/tn-peonee.jpg";
import BCP from "../../assets/portfolio/tn-bcp.jpg";
import Acusensus from "../../assets/portfolio/tn-acu.jpg";
import Latitude from "../../assets/portfolio/tn-lat.jpg";
import Link from "../../assets/portfolio/tn-link.jpg";
import Meme from "../../assets/portfolio/tn-meme.jpg";

const portfolioData = [
  {
    id: 1,
    image: Peonee,
    title: "Peonee",
    description: "Modern Australian contemporary eatery",
    website: "https://peoneerestaurant.com.au/",
    category: "Freelance",
  },
  {
    id: 2,
    image: BCP,
    title: "BCP Interiors",
    description: "Custom and personalised space",
    website: "https://bcpinteriors.com.au/",
    category: "Freelance",
  },
  {
    id: 3,
    image: Acusensus,
    title: "Acusensus",
    description: "Investor Centre",
    website: "https://investors.acusensus.com/investor-centre/",
    category: "Corporate",
  },
  {
    id: 4,
    image: Latitude,
    title: "Latitude Financial",
    description: "Investor Centre",
    website: "https://investors.latitudefinancial.com.au/investor-centre/",
    category: "Corporate",
  },
  {
    id: 5,
    image: Link,
    title: "Link Market Services",
    description: "Link Investor Centre",
    website: "https://investorcentre.linkgroup.com/Login/Login",
    category: "Corporate",
  },
  {
    id: 6,
    image: LegendsDental,
    title: "Legends Dental",
    description: "Professional dental clinic",
    website: "https://legendsdental.com.au/",
    category: "Freelance",
  },
  {
    id: 7,
    image: BlackJade,
    title: "Black Jade Tattoo",
    description: "Modern tattoo studio",
    website: "https://blackjadetattoo.com.au/",
    category: "Freelance",
  },
  {
    id: 8,
    image: Meme,
    title: "Meme Generator",
    description: "React App built with Vite.js",
    website: "https://leafy-jelly-ff7052.netlify.app/",
    category: "Other",
  },
];

export default portfolioData;
