import React from "react";
import "./service.css";
import { MdOutlineDesignServices } from "react-icons/md";
import { BsFileEarmarkCode } from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";

function Service(props) {
  return (
    <section id="service" ref={props.setService}>
      <div className="container">
        <h5
          className={
            props.setServiceAnimate ? "heading-animate" : "section-animateout"
          }
        >
          What Can I Offer
        </h5>
        <h2
          className={
            props.setServiceAnimate ? "heading-animate" : "section-animateout"
          }
        >
          Services
        </h2>
      </div>
      <div
        className={`container service__container ${
          props.setServiceAnimate ? "panel-animate" : "section-animateout"
        }`}
      >
        <article className="service__card">
          <BsFileEarmarkCode className="service__card-icon" />
          <h3>Web Development</h3>
          <small className="text-light">
            Delivering results focused services to develop high quality websites
            that are responsive to all devices.
          </small>
        </article>

        <article className="service__card">
          <MdOutlineDesignServices className="service__card-icon" />
          <h3>UI/UX Design</h3>
          <small className="text-light">
            Creating the cleanest and most aesthetic designs possible to give
            users a pleasant, user-friendly experience.
          </small>
        </article>

        <article className="service__card">
          <HiOutlineEmojiHappy className="service__card-icon" />
          <h3>Enthusiasm</h3>
          <small className="text-light">
            Giving it my all one project at a time, to ensure above and beyond
            client satisfaction.
          </small>
        </article>
      </div>
    </section>
  );
}

export default Service;
