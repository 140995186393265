import React from "react";
import "./experience.css";
import { ImHtmlFive } from "react-icons/im";
import { ImCss3 } from "react-icons/im";
import { SiJavascript } from "react-icons/si";
import { BsBootstrapFill } from "react-icons/bs";
import { SiJquery } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { SiAtom } from "react-icons/si";
import { BsWordpress } from "react-icons/bs";
import { SiAdobe } from "react-icons/si";
import { SiVisualstudiocode } from "react-icons/si";
import { FaFigma } from "react-icons/fa";
import { SiNotion } from "react-icons/si";

function Experience(props) {
  return (
    <section id="experience" ref={props.setExperience}>
      <div className="container">
        <h5
          className={
            props.setExperienceAnimate
              ? "heading-animate"
              : "section-animateout"
          }
        >
          What Skillsets I Have
        </h5>
        <h2
          className={
            props.setExperienceAnimate
              ? "heading-animate"
              : "section-animateout"
          }
        >
          Experience
        </h2>
      </div>
      <div
        className={`container experience__container ${
          props.setExperienceAnimate ? "panel-animate" : "section-animateout"
        }`}
      >
        {/* Front */}
        <div className="experience__front">
          <h3>Front-end Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <ImHtmlFive className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <ImCss3 className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsBootstrapFill className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaReact className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiJavascript className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiJquery className="experience__details-icon" />
              <div>
                <h4>jQuery</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
          </div>
        </div>
        {/* Tools */}
        <div className="experience__tools">
          <h3>Tools Used</h3>
          <div className="experience__content">
            <article className="experience__details">
              <SiVisualstudiocode className="experience__details-icon" />
              <div>
                <h4>Visual Studio</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiAtom className="experience__details-icon" />
              <div>
                <h4>Atom</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsWordpress className="experience__details-icon" />
              <div>
                <h4>WordPress</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaFigma className="experience__details-icon" />
              <div>
                <h4>Figma</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiAdobe className="experience__details-icon" />
              <div>
                <h4>Adobe Creative</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiNotion className="experience__details-icon" />
              <div>
                <h4>Notion</h4>
                <small className="text-light">Moderate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;
