import React from "react";
import { FiLinkedin } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

function HeaderSocials() {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/danl28/"
        target="_blank"
        rel="noreferrer"
      >
        <FiLinkedin />
      </a>
      <a
        href="https://www.instagram.com/dev.danew/"
        target="_blank"
        rel="noreferrer"
      >
        <BsInstagram />
      </a>
      <a href="mailto:info@danew.com.au" target="_blank" rel="noreferrer">
        <BsEnvelope />
      </a>
    </div>
  );
}

export default HeaderSocials;
