import React from "react";
import "./header.css";
import CTA from "./CTA";
import ProfilePhoto from "../../assets/profile-photo.jpg";
import HeaderSocials from "./HeaderSocials";

function Header(props) {
  return (
    <header ref={props.setHeader}>
      <div className="container header__container">
        <div className="profile-photo">
          <img src={ProfilePhoto} alt="profile-photo" aria-hidden />
        </div>
        <div className="header__container-content">
          <h5>Hello, I'm</h5>
          <h1>Daniel Le.</h1>
          <p className="text-light">
            A Web Developer that specialises in supporting businesses creatively
            by making their visions come to life in the digital space.
          </p>
          <CTA />
        </div>

        <HeaderSocials />
        <a href="#about" className="scroll__down">
          <div className="scroll__down-content"> Scroll Down</div>
        </a>
      </div>
    </header>
  );
}

export default Header;
