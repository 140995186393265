import React from "react";
import Resume from "../../assets/resume/Resume_DL_2023.pdf";

// Add PDF / download allows it to be downloadable

function Buttons() {
  return (
    <div className="cta">
      <a href={Resume} target="_blank" rel="noreferrer" className="btn">
        My Resume
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
}

export default Buttons;
